//lib
import React, { memo } from 'react';

//data
import { categories } from 'shared/components/Footer/data';

//components
import Accordion from 'shared/components/Footer/components/Accordion';

//constants
import { TITLES } from 'shared/components/Footer/constants';

function Categories() {
  return (
    <>
      <Accordion
        isHorizontal={false}
        heading={TITLES.EXPLORE_NYKAA_FASHION}
        links={categories}
      ></Accordion>
    </>
  );
}

export default memo(Categories);

// libs
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@nykaa/ui-components';
import { useRouteMatch, useLocation } from 'react-router';
import get from 'lodash/get';

// components
import Subscription from 'shared/components/Footer/components/Subscription';
import Links from 'shared/components/Footer/components/Links';
import Categories from 'shared/components/Footer/components/Categories/MobileCategories';
import Terms from 'shared/components/Footer/components/Terms';
import ListingFooter from 'shared/components/Footer/components/ListingFooter';
import GenericBrandDesc from 'shared/components/Footer/components/GenericBrandDesc';

import DownIcon from '@nykaa/ui-components/Icons/chevron-down';

//hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';
import { useDomainConfig } from 'shared/domainConfig/context/context';

// helpers
import { isWebView } from 'shared/helpers/utils/url';
import { useDynamicPage } from 'shared/routes/DynamicPage/helper';

// shared
import { RootState } from 'shared/store';

// styles

const Header = styled.label`
  display: block;
  position: relative;
  height: 44px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary10};
  padding: ${({ theme }) => theme.spacing.spacing60} 0;
  margin: 0 ${({ theme }) => theme.spacing.spacing80} 0;
  ${({ theme }) => theme.typography.bodyMedium};
`;

const Wrapper = styled.div`
  #footerContent {
    display: none;
  }

  .mainContent {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in;
  }

  #footerContent:checked ~ .mainContent {
    max-height: 550em;
  }

  #footerContent:checked ~ .header .downIcon {
    transform: rotate(180deg);
  }
`;
const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 10px;
  transition: all 0.2s ease-in-out;
`;

// constants
const KNOW_MORE = 'Know more';
const SCROLL_HEIGHT_ON_EXPAND = 420;

function Footer() {
  const ele = useRef(null);
  const location = useLocation();
  const isFooterVisible = useSelector(({ app }: RootState) => app.showFooter);
  const shouldHideFooter = isWebView(location) || !isFooterVisible;
  const { isDynamicPageURL } = useDynamicPage();
  const { ABOUT_FOOTER_TEXT } = useDomainConfig();

  const homePageRoute = useRouteMatch('/');
  const listingPageRoute = useRouteMatch('/*/c/:id');
  const searchPageRoute = useRouteMatch('/catalogsearch/result');

  const isListingPage =
    (listingPageRoute && listingPageRoute.isExact) || (searchPageRoute && searchPageRoute.isExact);
  const isHomePage = homePageRoute && homePageRoute.isExact;

  const showListingFooter = isListingPage || isDynamicPageURL;

  const showFooterCategories = get(useRemoteConfig('footerSection'), 'showCategories', false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked && ele.current) {
      setTimeout(() => {
        window.scrollTo({
          top: window.pageYOffset + SCROLL_HEIGHT_ON_EXPAND,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  if (shouldHideFooter) {
    return null;
  }

  return (
    <Wrapper>
      <input type="checkbox" name="footerContent" id="footerContent" onChange={handleChange} />

      <Header htmlFor="footerContent" className="header">
        {isListingPage ? KNOW_MORE : ABOUT_FOOTER_TEXT}
        <IconWrapper className="downIcon">
          <DownIcon />
        </IconWrapper>
      </Header>

      <div className="mainContent" ref={ele}>
        {isHomePage && showFooterCategories && <GenericBrandDesc />}
        {showListingFooter && <ListingFooter />}
        <Subscription />
        <Links />
        {showFooterCategories && <Categories />}
        <Terms />
      </div>
    </Wrapper>
  );
}

export default memo(Footer);

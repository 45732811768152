import { useLocation, useParams } from 'react-router';

export const useDynamicPage = () => {
  const { pathname } = useLocation();
  const { slug } = useParams<{ slug: string }>();

  // is url of the form: "/dp/"
  const isDynamicPageURL = pathname && pathname.startsWith('/dp/');

  return { isDynamicPageURL, slug };
};

import { styled } from '@nykaa/ui-components';

export const StyledAccordion = styled.div`
  margin: 0 10px;
  border-bottom: 1px solid #ccc;
  ${({ theme }) => theme.typography.bodySmall};

  &:last-child {
    border-bottom: 0;
  }

  .acc-title {
    position: relative;
    height: 45px;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) => theme.typography.subTitleMedium};
  }

  .acc-input {
    display: none;
  }

  .acc-list {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .acc-input:checked ~ .acc-list {
    max-height: 600px;
    padding-bottom: ${({ theme }) => theme.spacing.spacing10};
  }

  .acc-input:checked ~ .acc-title .expander {
    transform: rotate(180deg);
    transition: transform 0.8ms;
  }

  .acc-item {
    padding-bottom: 5px;
    ${({ theme }) => theme.typography.bodySmall};
  }

  .acc-anc {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }
  .horizontal-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: disc;
    & .acc-item {
      margin: 0 ${({ theme }) => theme.spacing.spacing60};
    }
    & .acc-anc:active {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const SectionHeading = styled.h2`
  ${({ theme }) => theme.typography.titleSmall};
  margin: ${({ theme }) => `${theme.spacing.spacing100} ${theme.spacing.spacing60}`};
`;
